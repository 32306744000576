import Analytics, { AnalyticsInstance } from 'analytics';
import { env } from '@/env';
import { AnalyticsJucyBookingFunnelPlugin, analyticsJucyBookingFunnel } from './plugins/analyticsJucyBookingFunnel';
import { AnalyticsEcommercePlugin, analyticsJucyEcommerce } from './plugins/analyticsJucyEcommerce';
import { analyticsJucyGtm } from './plugins/analyticsJucyGtm';
import { AnalyticsJucyInteractionsPlugin, analyticsJucyInteractions } from './plugins/analyticsJucyInteraction';

const sharedConfig = { debug: env.NEXT_PUBLIC_DEBUG_ANALYTICS };
type JucyAnalytics = AnalyticsInstance & {
    plugins: AnalyticsInstance['plugins'] & {
        interactions: AnalyticsJucyInteractionsPlugin['methods'];
        ecommerce: AnalyticsEcommercePlugin['methods'];
        bookingFunnel: AnalyticsJucyBookingFunnelPlugin['methods'];
    };
};
const analytics = Analytics({
    ...sharedConfig,
    app: 'oconee',
    plugins: [
        analyticsJucyGtm({ ...sharedConfig }),
        analyticsJucyInteractions({ ...sharedConfig }),
        analyticsJucyEcommerce({ ...sharedConfig }),
        analyticsJucyBookingFunnel({ ...sharedConfig }),
    ],
}) as JucyAnalytics;

export default analytics;
