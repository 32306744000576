import { AnalyticsPlugin } from 'analytics';
import { AnalyticsPluginArgs } from '../types/AnalyticsPluginArgs';

export const analyticsJucyGtm = (pluginConfig: { debug?: boolean } = {}): AnalyticsPlugin => ({
    name: 'google-tag-manager',
    config: pluginConfig,
    initialize: () => {
        if (typeof window === 'undefined') {
            return;
        }
        window.dataLayer = window.dataLayer || [];
    },
    page: ({ payload, config }: AnalyticsPluginArgs) => {
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log('dataLayer page', payload.properties);
        }
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push(payload.properties);
    },
    track: ({ payload, config }: AnalyticsPluginArgs) => {
        const { anonymousId, userId, properties } = payload;
        const formattedPayload = { ...properties };
        if (userId) {
            formattedPayload.userId = userId;
        }
        if (anonymousId) {
            formattedPayload.anonymousId = anonymousId;
        }
        if (!properties.category) {
            formattedPayload.category = 'All';
        }
        if (config.debug) {
            // eslint-disable-next-line no-console
            console.log('dataLayer push', {
                event: payload.event,
                ...formattedPayload,
            });
        }
        window.dataLayer = window.dataLayer || [];
        if (formattedPayload.ecommerce) {
            window.dataLayer.push({ ecommerce: null });
        }
        window.dataLayer.push({
            event: payload.event,
            ...formattedPayload,
        });
    },
    loaded: () => typeof window !== 'undefined',
});
