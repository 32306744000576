import { AnalyticsInstance } from 'analytics';
import { JucyAnalyticsPlugin } from '../types/JucyAnalyticsPlugin';

export type AnalyticsJucyInteractionsPlugin = JucyAnalyticsPlugin<{
    trackInteraction: (props: { source: string; category?: string; action?: string; label?: string }) => Promise<void>;
}>;

export const analyticsJucyInteractions = (pluginConfig: AnalyticsJucyInteractionsPlugin['config'] = {}): AnalyticsJucyInteractionsPlugin => ({
    name: 'interactions',
    config: pluginConfig,
    initialize: () => {
        if (typeof window === 'undefined') {
            return;
        }
    },
    methods: {
        trackInteraction(this: { instance: AnalyticsInstance }, props) {
            const data: Record<string, string | null> = {
                interaction_source: props.source,
                interaction_category: props.category || null,
                interaction_action: props.action || null,
                interaction_label: props.label || null,
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log('interaction', props);
            }
            return this.instance.track('interaction', data);
        },
    },
});
