import { AnalyticsInstance } from 'analytics';
import { format } from 'date-fns';
import { JucyAnalyticsPlugin } from '../types/JucyAnalyticsPlugin';

export type AnalyticsJucyBookingFunnelPlugin = JucyAnalyticsPlugin<{
    bookingInitiated: (props: { fleetType: string; pickUpLocation: string; pickUpDate: Date; dropOffLocation: string; dropOffDate: Date; coupon?: string; brand: string }) => Promise<void>;
}>;

export const analyticsJucyBookingFunnel = (pluginConfig: AnalyticsJucyBookingFunnelPlugin['config'] = {}): AnalyticsJucyBookingFunnelPlugin => ({
    name: 'bookingFunnel',
    config: pluginConfig,
    initialize: () => {
        if (typeof window === 'undefined') {
            return;
        }
    },
    methods: {
        bookingInitiated(this: { instance: AnalyticsInstance }, props) {
            const eventData = {
                step: 1,
                fleetType: props.fleetType,
                pickUpLocation: props.pickUpLocation,
                pickUpDate: format(props.pickUpDate, 'yyyy-MM-dd'),
                pickUpTime: format(props.pickUpDate, 'HH:mm'),
                dropOffLocation: props.dropOffLocation,
                dropOffDate: format(props.dropOffDate, 'yyyy-MM-dd'),
                dropOffTime: format(props.dropOffDate, 'HH:mm'),
                coupon: props.coupon,
                brand: props.brand,
            };
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log('bookingInitiated', eventData);
            }
            return this.instance.track('bookingInitiated', eventData);
        },
    },
});
