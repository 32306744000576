import { AnalyticsInstance } from 'analytics';
import { JucyAnalyticsPlugin } from '../types/JucyAnalyticsPlugin';

export type JucyEcommerceEventName = Gtag.EventNames;
export type JucyEcommerceEventParams = Gtag.EventParams & {
    item_list_id?: string;
    item_list_name?: string;
};

export type AnalyticsEcommercePlugin = JucyAnalyticsPlugin<{
    trackEcommerce: (event: JucyEcommerceEventName, props: JucyEcommerceEventParams) => Promise<void>;
}>;

export const analyticsJucyEcommerce = (pluginConfig: AnalyticsEcommercePlugin['config'] = {}): AnalyticsEcommercePlugin => ({
    name: 'ecommerce',
    config: pluginConfig,
    methods: {
        trackEcommerce(this: { instance: AnalyticsInstance }, event, props) {
            if (pluginConfig.debug) {
                // eslint-disable-next-line no-console
                console.log('ecommerce', event, props);
            }
            return this.instance.track(event, {
                ecommerce: props,
            });
        },
    },
});
